import { Box } from "@mui/material"
import { PoweredBy } from "components/PoweredBy/PoweredBy"
import LanguageMenu from "./LanguageMenu"
import { useTranslation } from "react-i18next"
import { STATICS } from "api/api"
// import estatetoolLogo from "logos/svg/estatetool-logo.svg";
// import BgImage from "logos/svg/maintenance-image.svg";

export const Maintenance = () => {
    const { i18n, i18n: { language } } = useTranslation()

    return <Box sx={{ height: "100vh", overflow: 'auto' }}>
        <Box sx={{ pt: { xs:2, lg: 5 }, px: { xs:2, lg: 5 }, height: { xs: "calc(100% - 50px)", lg: "calc(100% - 120px)" }, }}>
            <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: "space-between" }}>
            <Box
                component="img"
                sx={{
                    height: { xs: 50, lg: 109 },
                    width: { xs: 100, lg: 229 },
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                }}
                alt="The house from the offer."
                src={`${STATICS}/svg/estatetool-logo.svg`}
            />
            <LanguageMenu />
            </Box>
            <Box sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column', height: "100%", justifyContent: "space-between", pt: { xs:2, lg: 0 } }}>
                <Box  sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column', gap: '40px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                // height: 233,
                                width: 650,
                                // maxHeight: { xs: 233, md: 167 },
                                // maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="The house from the offer."
                            src={`${STATICS}/svg/maintenance-image.svg`}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column', textAlign: 'center', gap: '16px' }}>
                        <h1 className="h1">{ maintenancePage.desc[language] }</h1>
                        <Box className="paragraph1-regular" sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column', textAlign: 'center', gap: '30px', maxWidth: "770px", alignSelf: 'center' }}>
                            <p>{ maintenancePage.p1[language] }</p>
                            <p>{ maintenancePage.p2[language] }</p>
                        </Box>
                    </Box>
                </Box>
                <PoweredBy />
            </Box>
        </Box>
    </Box>
}



const maintenancePage = {
    desc: {
        "en": "We're fine-tuning the details!",
        "da": "Vi finpudser lige detaljerne!",
        "sv": "Vi finjusterar bara detaljerna!"
    },
    p1: {
        "en": "The site is temporarily under maintenance while we work to improve your experience. We are updating and optimizing our product so that you can soon have access to an even better home selector.",
        "da": "Siden er midlertidigt under vedligeholdelse, mens vi arbejder på at forbedre din oplevelse. Vi opdaterer og optimerer vores produkt, så du snart kan få adgang til en endnu bedre boligvælger.",
        "sv": "Sidan är tillfälligt nere för underhåll medan vi arbetar för att förbättra din upplevelse. Vi uppdaterar och optimerar vår produkt, så snart får du tillgång till en ännu bättre enhetsväljare."
    },
    p2: {
        "en": "Thanks for your patience - we'll be back soon, and it will definitely be worth the wait!",
        "da": "Tak for din tålmodighed - vi er snart tilbage, og det bliver helt sikkert ventetiden værd!",
        "sv": "Tack för ditt tålamod - vi är tillbaka snart, och det kommer definitivt att vara värt väntan!"
    }
}