// import './wdyr'
import './i18n.jsx'
import 'window.jsx'

import React      from 'react'
import ReactDOM   from 'react-dom/client'

import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Navigate, Route, Routes }  from 'react-router-dom'
import { GlobalStyle }    from 'styles/GlobalStyle'

import { Boot } from 'main/Boot'
import { Head } from 'main/Head'

import { ProjectData_Mono } from 'main/ProjectData_mono'
import { ProjectData_Poly } from 'main/ProjectData_poly'

import { PreloadFrames_Map }      from 'main/Preloading/PreloadFrames_map'
import { PreloadFrames_Poly }     from 'main/Preloading/PreloadFrames_poly'
import { PreloadFrames_Mono }     from 'main/Preloading/PreloadFrames_mono'
import { PreloadFrames_Subs }     from 'main/Preloading/PreloadFrames_subs'
import { PreloadFrames_Tiny }     from 'main/Preloading/PreloadFrames_tiny'
import { PreloadFrames_TinySubs } from 'main/Preloading/PreloadFrames_tinysubs'
import { PreloadSvg_Overlays }    from 'main/Preloading/PreloadSvg_Overlays'
import { PreloadSvg_OverlaysSub } from 'main/Preloading/PreloadSvg_OverlaysSub'
import { GetIP }                  from 'main/GetIP'

import { Language } from 'main/Language'

import { PreloadCompares } from 'main/PreloadCompares'
import { App }             from './App'
import { useTranslation } from 'react-i18next'
import { useGet } from 'state/jotai.jsx'
import { isMaintence } from 'state/store.global.jsx'
import AppThemeProvider from 'theme/ThemeProvider.jsx'
import { Maintenance } from 'components/ErrorsPage/Maintenance.jsx'
import { ErrorBoundary } from 'ErrorBoundary.jsx'

const strict = false

const Main = () => {

  const { i18n:{language} } = useTranslation()
      
  const isMaintencePage = useGet(isMaintence)
  // If maintenance mode is active, redirect to "under-maintenance"
  if (isMaintencePage) {
    return <BrowserRouter>
      <AppThemeProvider>
        <Language />
        <GlobalStyle />
        <Navigate to={`/${language}/under-maintenance`} replace />
      <Routes>
        <Route path={`/${language}/under-maintenance`} element={<Maintenance />} />
      </Routes>
      </AppThemeProvider>
    </BrowserRouter>;
  }

  
  return (
    <HelmetProvider>
       <ErrorBoundary fallback={<Maintenance />}></ErrorBoundary>
      <Head />
      <Boot />
  
      <GetIP/>
      {/* <ProjectData_Mono /> */}
      <ProjectData_Poly />
  
      {/* <PreloadFrames_Map /> */}
      {/* <PreloadFrames_Mono /> */}
      <PreloadFrames_Poly />
      {/* <PreloadFrames_Subs /> */}
      <PreloadFrames_Tiny />
      {/* <PreloadFrames_TinySubs /> */}
  
      <PreloadSvg_Overlays />
      {/* <PreloadSvg_OverlaysSub /> */}
  
      <PreloadCompares />
  
      <BrowserRouter>
        <GlobalStyle/>
        <Language />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  )
}

ReactDOM.createRoot(
  document.getElementById('root')
).render(
  strict 
  ? <React.StrictMode>
      <Main />
    </React.StrictMode> 
  : <Main />
)