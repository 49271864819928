import { Box, Grow, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, useMediaQuery, } from '@mui/material'
import { Icon } from 'components/Icons'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { _project } from 'state/store.projects'
import { getFocus, setFocus, useAtom, useGet } from 'state/jotai'
import { active_project, current_language } from 'state/store.global'
import { _bootstrap } from 'state/store.bootstrap'
import useLanguage from 'components/LanguageMenu/useLanguage'

function LanguageMenu() {

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = useState(null)
  const anchorRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [, setCurrent_language] = useAtom(current_language);


  const navigateTo = useNavigate()
  const here = useLocation().pathname

  const { i18n, i18n: { language } } = useTranslation()
//   const navigateTo = useNavigate()
//   const here = useLocation().pathname
//   const { pin, pid } = useGet(active_project) 
//   const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleToggle = () => {
    setAnchorEl(anchorRef.current)
    setIsOpen(isOpen => !isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }

  const handleChange = id => {
      setCurrent_language(id)
      i18n.changeLanguage(id)
      handleClose()
      navigateTo(here.replace(language, id))
  }

  const totalItems = options?.length;
  
  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'flex-start', zIndex: 1000, userSelect: 'none', }} ref={anchorRef} >
        <IconButton onClick={handleToggle}>
        <Icon color={"#000"} icon='globe' isFaIcon={true} fontSize='inherit' />
        <Icon color={"#000"} icon='caretDown' sx={{ position: 'absolute', right: -8, bottom: 13, fontSize: 10, transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0)', fill: "#000" }} ></Icon>
        </IconButton>
        <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        anchorReference={isMobile ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={{ top: 70, left: 400 }}
        PaperProps={{ overflow: "hidden !important", elevation: 1, sx: { width: isMobile ? '100%' : 300, left: isMobile ? '0 !important' : 0, maxWidth: '100%', }, }}
        sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.25)', }, '& .MuiPaper-root': { backgroundColor: `rgba(43, 60, 73, 0.95)`, color: '#fff', borderRadius: "6px", overflow: "hidden !important", 
            // top: '10px !important'
         }, '& .MuiList-root': { padding: "0px", }, }}
        TransitionComponent={Grow}
        >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "16px", borderBottom: `1px solid #fff` }}>
            <div className='h5'>
                { languageDropdownDesc[language] }
            </div>
            <div style={{ cursor: 'pointer', marginRight: '10px',position : 'absolute',right : '10px', display: 'flex' }} onClick={() => { setIsOpen(false) }}><Icon icon='close' sx={{ width: '15px', height: '15px'}} color={clr?.primary_text} /></div>

        </div>

        <div style={{ overflowY: "auto",overflowX : 'hidden', maxHeight: "236px", padding: "24px 0" }}>
            <>
            {options.map((item, i) => {
                return (
                <div style={{ padding: '0px 24px' }} key={i}>
                    <MenuItem key={i} onClick={() => handleChange(item.id)} sx={{ gap: '10px', borderBottom: i === totalItems - 0 ? 'none' : `1px solid #fff`, padding: i === 0 ? '0 0 16px 0' : '16px 0px' }}>
                    <ListItemIcon>
                        <SvgIcon sx={{ fontSize: 25 }}>{item.icon}</SvgIcon>
                    </ListItemIcon>
                    <div className='paragraph1-regular'
                    style={{ color: "#fff" }}
                    >
                        {item.label[language]}
                    </div>
                    </MenuItem>
                </div>
                )
            })}
            </>
        </div>
        </Menu>
    </Box>
    </>
  )
}

export default LanguageMenu

const languageDropdownDesc = {
  "en": "Choose the language you want:",
  "da": "Vælg det sprog du ønsker:",
  "sv": "Välj det språk du vill ha:",
}

const options = [
    {
      id: "da",
      label: {'en': 'Danish', 'da': 'Dansk','sv': 'Danska'},
      icon: (
        <>
          <path fill="#be003a" d="M24 4.999h-24v14.002h24v-14.002z"></path>
          <path fill="#fff" d="M10.384 4.999h-2.596v14.002h2.596v-14.002z"></path>
          <path fill="#fff" d="M24 10.698h-24v2.596h24v-2.596z"></path>
        </>
      ),
    },
    {
      id: "sv",
      label: {'en': 'Swedish', 'da': 'Svensk', 'sv': 'Svenska'},
      icon: (
        <>
          <rect y="4" width="24" height="16" fill="#005cbf" />
          <rect x="0" y="10" width="24" height="4" fill="#ffd700" />
          <rect x="6" y="4" width="4" height="16" fill="#ffd700" />
        </>
      ),
    },
    {
      id: "en",
      label: {'en': 'English', 'da': 'Engelsk','sv': 'Engelska'},
      icon: (
        <>
          <path fill="#fff" d="M24 4.865h-24v14.27h24v-14.27z"></path>
          <path
            fill="#bd0034"
            d="M16.069 10.025l7.931-4.526v-0.634h-0.543l-9.046 5.16h1.659z"
          ></path>
          <path
            fill="#bd0034"
            d="M15.697 14.259l8.303 4.737v-0.947l-6.644-3.79h-1.659z"
          ></path>
          <path
            fill="#bd0034"
            d="M0 5.951l7.101 4.074h1.666l-8.768-5.022v0.947z"
          ></path>
          <path
            fill="#bd0034"
            d="M8.389 14.259l-8.389 4.788v0.087h1.516l8.532-4.876h-1.659z"
          ></path>
          <path fill="#1a237b" d="M22.362 4.865h-8.41v4.796l8.41-4.796z"></path>
          <path
            fill="#1a237b"
            d="M10.248 4.865h-8.374l8.374 4.796v-4.796z"
          ></path>
          <path fill="#1a237b" d="M24 10.025v-3.272l-5.7 3.272h5.7z"></path>
          <path fill="#1a237b" d="M24 17.495v-3.236h-5.7l5.7 3.236z"></path>
          <path
            fill="#1a237b"
            d="M2.353 19.135h7.895v-4.511l-7.895 4.511z"
          ></path>
          <path
            fill="#1a237b"
            d="M13.952 19.135h7.924l-7.924-4.511v4.511z"
          ></path>
          <path fill="#1a237b" d="M0 14.259v3.389l5.9-3.389h-5.9z"></path>
          <path fill="#1a237b" d="M0 10.025h5.9l-5.9-3.389v3.389z"></path>
          <path
            fill="#bd0034"
            d="M10.984 4.865v6.005h-10.984v2.544h10.984v5.721h2.231v-5.721h10.784v-2.544h-10.784v-6.005h-2.231z"
          ></path>
        </>
      ),
    },
  ]
  