import { useEffect }                    from "react";
import {
  Svg,
  Circle,
  Group,
  Line,
  Pos,
  Lab,
  Outer,
  Text1,
  Text2,
  Path,
  CircleGroup,
}                                       from "./Overview.label.style";

import { useLocation }                  from "react-router-dom";
import { useHover }                     from "hooks/useHover";
import { useAtom }                      from "jotai";
import { a_labHovered, active_project, vis_filter, vis_desktop_nav  } from "state/store.global";
import { useClick }                     from "./Overview.label.click";
import { _project }                     from "state/store.projects";
import { getFocus, useGet }             from "state/jotai";
import { close_drawer } from "state/actions";

export const Label = ({ c, sc, d, l, rmode, r, labelsInfo }) => {
  if (c === undefined || r === undefined) return null;
  const { pin, pid }         = useGet(active_project);
  const unitStatus           = getFocus(_project, "unitStatuses")?.[pin]?.[pid] ?? [];
  const availableTranslation = 
    unitStatus?.filter((u) => u.ID === 10)?.[0]?.Name ?? "";

  const here = useLocation().pathname.split("/");

  const setLabHovered = useAtom(a_labHovered)[1];
  const buttonsLabel  = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isOverview = here[here.length - 1] == "isometry";

  const [hoverRef, isHovered] = useHover();
  const click                 = useClick();

  const s    = 0.6 / sc === Infinity ? 10 : 0.6 / sc;
  const move = (cx, cy, dir = "-") => ({
    transform: `rotate(${dir}${d} ${cx} ${cy})`,
  });
  const g           = { ...move(50, 50, "+") };
  const svg         = { viewBox: "0 0 100 100" };
  const circ1       = { cx: 0, cy: 0, r: 35, clr: clr };
  const circ2       = { cx: 0, cy: 0, r: 55, fillOpacity: "0.5", clr: clr };
  const circ3       = { cx: 0, cy: 0, r: 75, fillOpacity: "0.3", clr: clr };
  const cx          = r?.[0];
  const cy          = r?.[1];
  const circleGroup = { transform: `translate(${cx} ${cy})` };
  const path1 = { d: `M ${cx} ${cy} L 50 ${-l + 50}`, clr: clr };

  const dl = { d, l, s };
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const [, setShowFilter] = useAtom(vis_filter);
  const closeDrawer = useAtom(close_drawer)[1];

  useEffect(() => {
    setLabHovered(
      isHovered ? { name: labelsInfo?.Presentation }: { name: "" }
    );
  }, [isHovered]);

  if (pin === 0 && !rmode)
    return (
      <>
        <Pos c = {c}>
          <Svg {...svg}>
            <Group>
              <Path {...path1} />
            </Group>

            <CircleGroup {...circleGroup}>
              <Circle {...circ1}></Circle>
              <Circle {...circ2}></Circle>
              <Circle {...circ3}></Circle>
            </CircleGroup>
          </Svg>
          <Outer {...dl}>
            <Lab
              {...dl}
              clr     = {clr}
              ref     = {hoverRef}
              onClick={() => {click(labelsInfo); closeDrawer("detail"); setShowFilter(false); setShowMenu(false);}}
            >
              <Text1 s = {s}>{labelsInfo?.Presentation}</Text1>
              <Text2 s = {s}>{`${labelsInfo?.available_Units} ${availableTranslation?.toLowerCase()}`} { buttonsLabel?.filter( (btn) => btn.UILabelProperty === "boliger"
                  )[0]?.Label?.toLowerCase()
                }</Text2>
            </Lab>
          </Outer>
        </Pos>
      </>
    );
};

const style = {
  zIndex        : 1000,
  background    : "black",
  color         : "white",
  height        : 80,
  width         : 220,
  position      : "absolute",
  display       : "flex",
  justifyContent: "center",
  alignItems    : "center",
  fontSize      : "1.6rem",
};
