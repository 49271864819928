import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Hotkeys } from 'hotkeys'
import { useSetActiveProject } from 'hooks/useSetActiveProject'
import { useSearchParams } from 'react-router-dom'
import { setProjectData, getProjectData } from 'state/jotai.projectDataHooks'
import { minMax } from 'utilities/utility.minMax'

export const Root = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const room = searchParams.get('rooms')
  const rooms = parseInt(room) + 1
  const status = searchParams.get('status')
  const size = searchParams.get('size')
  const rent = searchParams.get('rent') || searchParams.get('Husleje');
  const boligtype = searchParams.get('boligtype')
  const getActiveFilters = getProjectData('activefilters')
  const setActiveFilters = setProjectData('activefilters')

  const data = (() => {
    const dropdownOption = {
      ledig: "UnitStatusLedig",
      udlejet: "UnitStatusUdlejet",
      reserveret: "UnitStatusReserveret",
    }
    // const statusParam = status === 'tillgangila'
    const statusParam = status ? true : false
    const roomsParam = rooms ? true : false
    const sizeParam = size ? true : false
    const rentParam = rent ? true : false
    const alterRoomVal = room?.split('-').length === 2 ?  room?.split('-')?.map(r => `${parseInt(r)+1}`).join('-') : room

    const interval = roomsParam ? [{ property: 'Etage', min: minMax(alterRoomVal)[0], max: minMax(alterRoomVal)[1] }] : []
    sizeParam && interval.push({ property: 'Areal', min: minMax(size)[0], max: minMax(size)[1] })
    rentParam && interval.push({ property: 'Husleje', min: minMax(rent)[0], max: minMax(rent)[1] })
    const multiSelect = statusParam ? [{ property: 'Status', values: status.split(',').map(s => dropdownOption[s]) }] : []
    const dropdown = boligtype ? boligtype : ""
    return  { interval, multiSelect, dropdown }
  })()

  const ready = useSetActiveProject()

  useEffect(() => {
    if (ready) {
      setActiveFilters(data)
    }
  }, [ready])

  return (
    <>
      <Hotkeys />{' '}
      {/* Hotkeys are added here so that they can access the react router API */}
      <Outlet /> {/* Outlet is where all Views are rendered */}
    </>
  )
}
