import styled, { css }             from 'styled-components/macro'
import { _project }                from 'state/store.projects'
import { vis_overlay }             from 'state/store.global'
import { active_unit }             from 'state/store.global'
import { useGet, useAtom }         from 'state/jotai'
import { useUnit, usePick }        from 'components/Isometry/Isometry.utilities'
import { motion, AnimatePresence } from 'framer-motion'
import { open_drawer }             from 'state/actions'

export const Svg = ({ svg, percent, units }) => {

  const vbox = svg?.attributes?.viewBox
  const blur = Math.abs(percent-100)/3
  const opac = percent/100
  const draw = useAtom(open_drawer)[1]
  const geta = useAtom(active_unit)[0]
  const seta = useAtom(active_unit)[1]
  const show = useGet(vis_overlay)
  const pick = usePick()
  const unit = useUnit()

  const container = {
    hidden: { opacity:0 },
    show: { 
      opacity: 1,
      // duration: 0.1,
      // transition: { staggerChildren: 0.005, staggerDirection:-1 }
     },
  }

  const item = {
    hidden: { opacity:0 },
    show: { opacity:1 },
  }
  
  return (
    <AnimatePresence>
    {
      units && show && (
        <svg viewBox={vbox} style={{position:'absolute'}}>
          { 
            svg?.children?.map( ({attributes:{id,d}}) => {
              const ID      = id?.replace(/nr/g,'')
              const address = isNaN(ID) ? '' : unit(ID,'Adresse')
              const sID     = isNaN(ID) ? '' : unit(ID,'UnitStatusID')
              const sName   = isNaN(ID) ? '' : unit(ID,'UnitStatusName')
              const color   = isNaN(ID) ? '#ffffff00' : unit(ID,'UnitColor','f')
              const picked  = isNaN(ID) ? '' : pick(ID)
              const UnitID  = isNaN(ID) ? '' : unit(ID,'UnitID')
              const Unit    = isNaN(ID) ? '' : unit(ID,0,'i')
              const isActive = geta.UnitID === UnitID
              const onClick = () => {              
                if ( UnitID === false ) return 
                if ( isNaN(ID) ) navigate(`/${language}/isometry/${ID}`)
                if ( sID == 40 ) return
                if ( sID !== 40 ) {
                  seta( Unit )
                  draw('detail')
                  log({action:'clicked_apartment_on_isometry',unitId:UnitID, logData: { "unitId": UnitID}})
                }
              }
              const style = {filter:`blue(${blur}px)`,opacity:isNaN(ID) ? '1' : opac}
              const key = d
              const path = { d, id, key, style, onClick, sID, sName, color, picked, active:isActive, ID }
              if ( !d.includes('M0') ) return <Path {...path}/>
          })}
        </svg>
      )
    }
    </AnimatePresence>
  )
}
const Path = styled.path( ({sID,color,picked,active,ID}) => css`
  cursor: ${ sID == 40 ? 'not-allowed' : 'pointer' };
  fill: ${ (() => {
    if ( active ) return '#FFFFFF90'
    if ( picked ) return '#FF00FF'
    if ( color.value == 'err' ) {
      if ( sID == 10 ) return '#00ff0080'
      if ( sID == 20 ) return '#ffff0080'
      if ( sID == 30 ) return '#0000ff80'
      if ( sID == 40 ) return '#ff000080'
    }
    if ( isNaN(ID) ) {
      return color
    }
    return ( typeof color === 'string' ) 
      ? color + '80' 
      : '#00000080'
  })() };
  ${ typeof color !== 'string' } {
    :hover { fill: #ffffff80; }
  }
`)

