import { useEffect }                from 'react'
import { useFetch }                 from 'hooks/fetch/useFetch'
import { _bootstrap }               from 'state/store.bootstrap'
import { active_project, compares, current_language } from 'state/store.global'
import { comparesData }             from 'state/store.global'
import { useAtom, useGet, useSet }           from 'state/jotai'
import { COMPARE }                  from 'api/api'
import { useSWRConfig }             from 'swr'

export const PreloadCompares = () => {
  
  const currentLanguage = useAtom(current_language)[0];
  const comps        = useGet(compares).join(',')
  const setData      = useSet(comparesData)
  const { pin, pid } = useGet(active_project);
  const { D }        = useFetch({ url:COMPARE(pid,comps, currentLanguage), file:'PreloadCompares.jsx'})

  useEffect(()=>{
    if ( D ) {
      setData( D )
    }
  },[D,comps, currentLanguage])

  return null
}
