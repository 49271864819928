import { ISO_FRAMES } from 'api/api'
import styled from 'styled-components/macro'
import { preloadImage } from 'utilities/utility.images'

export const Tiny = ({tiny, dim, status, percent, frame, config}) => {
  // const i = frames.findIndex(i=>Object.keys(i)[0] == frame)
  // const img = frames?.[i]?.[frame]
 
    const URL = ISO_FRAMES+config.PNG_PATH+"/"+frame+".png"
    const IMG = preloadImage(URL)
    console.log(IMG, 'frame')
  
  const blur = Math.abs(percent-100)/3
  const opac = percent/100
  return (
    <>
      { status !== 'DONE' &&
        <StyledTiny
          src={IMG.src}
          w={dim.W} 
          h={dim.H} 
          alt={''}
          style={{filter:`blur(${blur}px)`,opacity:opac}}/>
      }
    </>
  )

}

const StyledTiny = styled.img`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  /* filter: ${({ blur }) => `blur(${blur}px)`}; */
  position: absolute;
`