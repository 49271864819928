import { Box, IconButton } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import { toggle_drawer } from "state/actions";
import { useAtom } from "state/jotai";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function TopDrawer({ availableDate, backgroundColor, textColor, statusText, statusId, address, moveindate}) {
  const toggle = useAtom(toggle_drawer)[1];
  const todaydate = new Date().getTime();


  const [day, month, year] = availableDate.split('-').map(Number);
  const availableDateObj = new Date(year, month - 1, day);
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const handleClose = () => {
    toggle("detail");
  };

  return (
    <Box
      sx={{
        backgroundColor,
        color: textColor,
        py: { xs: 1, md: 1.5 },
        px: 2.5,
        height: 59
      }}
    >
      <IconButton
        onClick={handleClose}
        color="inherit"
        size="small"
        sx={{
          position: "absolute",
          right: 18,
          top: 12,
          cursor: "pointer",
        }}
      >
        <Icon icon="close" fontSize="inherit" />
      </IconButton>
      <div>
        <strong style={{ display: 'inline-block', marginRight: '8px' }}>{statusText}</strong>

        {statusId === 10 && moveindate ? (
          availableDateObj <= todaydate ? (
            <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
              {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
            </p>
          ) : (
            <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
              ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {availableDate.split('-').join('.')})
            </p>
          )
        ) : null}

      </div>
      <Box mt={0.5} sx={{ width: "95%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{address}</Box>
    </Box>
  );
}

TopDrawer.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  statusText: PropTypes.string,
  address: PropTypes.string,
};

TopDrawer.defaultProps = {
  backgroundColor: "#AAAAAA",
  textColor: "#000000",
  statusText: "Ledig",
  address: "",
};

export default TopDrawer;
