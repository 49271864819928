import { animate } from 'framer-motion'
import { getFocus, setFocus } from 'state/jotai'
import { _isometry } from 'state/store.isometry'

export const useSnapLogic = ({
  value,
  setFrame,
  tweens,
  setShow,
  setIndex,
  SNP,
  setFrameFocus,
  pids,
  allConfig,
  pin
}) => {
  // Determine within which tween the current stop frame is
  const SECT = tweens?.findIndex(i => i.includes(value))
  const TWEEN = tweens?.[SECT]
  
  // Find center of tween
  const CEN = TWEEN?.[Math.floor(TWEEN?.length / 2 || 0)]
  
  // Determine whether snapping should go up or down
  const DIR = value > CEN ? 'UP' : 'DOWN'
  
  // Determine the frame of snapping
  const FRAME = DIR === 'UP' ? TWEEN?.[TWEEN?.length - 1 || 0] : TWEEN?.[0]

  // clog( 'usesnaplogic, index: ' , SNP?.indexOf(FRAME))

  // Set the snap, show and index
  setIndex(SNP?.indexOf(FRAME))
  
  setShow(true)
  
  if(pin === 0) {
    animate(value, FRAME, {
      duration: 0.5,
      onUpdate: v => setFrameFocus((f) => (pids.map((p, cnfIndex) => {
        const overviewPid = allConfig?.[0]?.[pids[0]]?.ANGLES;
        if(cnfIndex === 0) {
          return {
            [p]: +v.toFixed(0)
          }
        }else {
          return {
            [p]: overviewPid?.[SNP?.indexOf(FRAME)]?.[p]?.[p]
          }
        }
      })))
    })
  }else {
    animate(value, FRAME, {
      duration: 0.5,
      onUpdate: v => setFrame(+v.toFixed(0)),
    })
  }
}