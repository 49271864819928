import { _project }          from 'state/store.projects'
import { _bootstrap }        from 'state/store.bootstrap'
import { get, getFocus, useGet }       from 'state/jotai'
import { WDay, MDay, Month } from './OpenHouseBanner.utilities'
import { active_project } from 'state/store.global'

export const useOpenHouseData = () => {

  const sitename  = useGet(_bootstrap).sitename
  const openhouse = get(_project, 'openhouse')

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const Y = sitename ?? 'SITENAME'
  const P = openhouse?.FromDate    || '' // Remember safari requires specific format of dates
  const X = openhouse?.Title       || ''
  const W = openhouse?.FromTime    || ''
  const V = openhouse?.ToTime      || ''
  const I = buttonsLabel?.filter( btn => btn?.UILabelProperty == 'OpenHouseAddress')[0]?.Label || ''
  const K = openhouse?.Description || ''
  // const url = openhouse?.url || ''

  const Q = `${MDay(P) ?? ''}`
  const R = `${Month(P) ?? ''}`
  const S = `${X}`
  // const T = `${WDay(P)[0].toUpperCase() + WDay(P).slice(1).toLowerCase()} ${buttonsLabel?.filter( btn => btn?.UILabelProperty == 'kl')[0]?.Label} ${W} - ${V}`
  const T = `${WDay(P)[0].toUpperCase() + WDay(P).slice(1).toLowerCase()} ${buttonsLabel?.filter( btn => btn?.UILabelProperty == 'kl')[0]?.Label} ${W}` // hiding end time
  const U = `${I}`
  const L = `${K}`
  

  return {
    ready      : W && V,
    MM         : Q,
    MMM        : R,
    Title      : S,
    Time       : T,
    Address    : U,
    Description: L,
    // Url        : url
  }


}