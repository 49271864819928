import { Button, Menu } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useAtom } from "state/jotai";
import { iso_free } from "state/store.global";

const Dropdown = forwardRef(({ children, buttonText, selectedLabel, width, face,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  xs,
  marginLeft, }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [, setRmode] = useAtom(iso_free)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setRmode(false)
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // Expose child method through the ref
  useImperativeHandle(ref, () => ({
    closeMenu() {
      setAnchorEl(null);
    },
  }));

  return (
    <>
      <Button
        onClick={handleOpenMenu}
        sx={{
          color: color ? color : "#000000",
          fontWeight: fontWeight ? fontWeight : 400,
          fontSize: fontSize ? fontSize : 16,
          lineHeight: lineHeight,
          px: 0,
          width: "max-content",
        }}
              
        endIcon={
          <Icon
            icon="caretDown"
            sx={{
              fontSize: "10px !important",
              mt: "0.5em",
              pointerEvents: "none",
              color: "text.primary",
              transform: open ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          />
        }
      >
        {buttonText}
          <span style={{ fontWeight: 400 }}>{face}</span>
          {selectedLabel ? `: ${selectedLabel}` : ""}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginLeft: marginLeft,
          width: width,
          "& .MuiList-root": {
            py: "18px",
          },
          "& .MuiMenuItem-root": {
            fontSize: 16,
            py: "16px",
            px: "20px",
            "&:hover": {
              color: "primary.main",
              backgroundColor: "rgba(217, 217, 217, 0.35)",
            },
          },
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            ml: { xs: xs ? xs : "16px", md: "0" },
            backgroundColor: "rgba(249, 249, 249, 0.95)",
            width: 400,
            maxWidth: "100%",
            maxHeight: 400,
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
});

Dropdown.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
};

Dropdown.defaultProps = {
  buttonText: "",
};

Dropdown.displayName = "Dropdown";

export default Dropdown;
