export const Arrow = ({ D, fill, w=25, click, style, percent }) => {

  const R = 'm9 7.2 5 4.8-5 4.8 1.7 1.7 6.6-6.5-6.5-6.5zm15 4.8a12 12 0 11-12-12 12 12 0 0112 12z'
  const L = 'M15 7.2 10 12l5 4.8-1.7 1.7L6.7 12l6.5-6.5ZM0 12A12 12 0 1 0 12 0 12 12 0 0 0 0 12Z'
  const STY = {
    width: w,
    cursor: 'pointer',
    zIndex: 0,
    ...style,
  }

  return (
    <>
     {
        percent == 100 &&  <svg 
        onClick={click} 
        style={STY}
        viewBox="0 0 24 24" 
        width={w}
        xmlns="http://www.w3.org/2000/svg"

      >
        <path d={D == 'R' ? R : L } fill={fill}/>
      </svg>
      }
    </>
  )

}